<template>
  <div>
    <form v-if="isInitial || isSaving" enctype="multipart/form-data" novalidate>
      <h1>{{ title }}</h1>
      <div class="dropbox">
        <input
          ref="file"
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          :accept="accept"
          class="input-file"
          @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
        >
        <p v-if="isInitial">
          Drag your file(s) here to begin<br> or click to browse
        </p>
        <p v-if="isSaving">
          Uploading {{ fileCount }} files...
        </p>
      </div>
    </form>
    <!--SUCCESS-->
    <div v-if="isSuccess">
      <p>
        Uploaded file(s) successfully.
        <a href="#" @click.prevent="reset()">Upload again</a>
      </p>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <p class="text-danger">
        Upload failed. <br>
        {{ errMessage }} <br>
        <a href="#" @click.prevent="reset()">Try again</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>

    <blueprint-data
      v-if="apiRoutePath" ref="blueprintdata" :api-route-path="apiRoutePath" :fields="filesFields" no-export no-new
      no-edit no-search
    >
      <template v-slot:updatedAt="{ item }">
        <span>{{ item.updatedAt | moment('lll') }}</span>
      </template>
      <template v-slot:originalName="{ item }">
        <a href="#" target="_blank" @click.prevent="downloadFile(item.id)">{{ item.file.originalName }}</a>
      </template>
    </blueprint-data>
  </div>
</template>

<style lang="scss">
@import 'larva/_variables.scss';
@import 'larva/functions.scss';
  .dropbox {
    outline: .2rem dashed lar-color(primary, base);
    outline-offset: -1rem;
    background: lar-color(primary, base, 0.2);
    color: lar-color(primary, base);
    height: 10rem;
    position: relative;
    line-height: 10rem;
    cursor: pointer;
  }

  .input-file {
    opacity: 0;
    position: absolute;
    width: 100%; height: 100%;
    cursor: pointer;
  }

  .dropbox:hover {
    background: lar-color(primary, base, 0.9);
    color: lar-color(primary, contrast);
    outline-color: lar-color(primary, contrast);
  }
  .dropbox p {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    width: 100%;
  }
</style>

<script>
import * as FileDownload from 'js-file-download';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

export default {
  props: ['accept', 'title', 'name', 'apiRoutePath'],
  data () {
    return {
      uploadError: null,
      errMessage: '',
      currentStatus: null,
      uploadFieldName: 'files',
      filesFields: [
        {
          key: 'originalName',
          label: 'File',
          slot: true,
        },
        {
          key: 'updatedAt',
          label: 'Date',
          slot: true,
        },
      ],
    };
  },
  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  mounted () {
    this.reset();
    if (this.name) {
      this.uploadFieldName = this.name;
    }
  },
  methods: {
    downloadFile (id) {
      this.$http({
        url: `${this.apiRoutePath}/${id}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((res) => {
        const disposition = res.headers['content-disposition'];
        const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
        FileDownload(res.data, filename);
      }).catch(this.err);
    },
    reset () {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadError = null;
    },
    save (formData) {
      this.errMessage = '';
      this.$http({
        method: 'post',
        url: this.apiRoutePath,
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
        timeout: (10000 * 60), // 10 min
      })
      .then(() => {
        this.currentStatus = STATUS_SUCCESS;
        if (this.$refs.blueprintdata) {
          this.$refs.blueprintdata.getData();
        }
      })
      .catch((err) => {
        this.errMessage = err.response && err.response.data ? err.response.data.message || err.message : err.message;
        this.currentStatus = STATUS_FAILED;
      });
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
    },
    filesChange (fieldName, fileList) {
      // handle file changes
      const formData = new FormData();
      if (!fileList.length) {return;}
      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map((x) => {
          formData.append(fieldName, fileList[x], fileList[x].name);
          return formData;
        });
      // save it
      this.save(formData);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      throw err;
    },
  },

};
</script>
