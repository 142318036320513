<template>
  <div>
    <b-modal ref="range" no-enforce-focus hide-header>
      <form @submit.prevent="editRange">
        <date-range v-model="range" :state="state('range')" />
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="editRange()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal ref="owner" no-enforce-focus hide-header>
      <form @submit.prevent="editOwner">
        <b-form-group label="Owner User" label-for="user">
          <blueprint-search-input
            id="user"
            v-model="ownerId"
            :state="state('ownerId')"
            :custom-label="customUserLabel"
            placeholder="Type to search"
            api-route-path="users"
          />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="editOwner()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal ref="tax" no-enforce-focus hide-header>
      <form @submit.prevent="editTaxPrice">
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Price" label-for="price" description="Without taxes">
          <b-row>
            <b-col>
              <b-form-input id="price" v-model="pricetax.price" step="0.01" :state="state('price')" type="number" placeholder="Price" />
            </b-col><b-col>
              <b-form-select v-model="pricetax.priceMethod" :state="state('priceMethod')">
                <option value="DAY">
                  Per-day
                </option>
                <option value="MONTH">
                  Per-month
                </option>
                <option value="MONTH_PER_M2">
                  Per-month per m2
                </option>
              </b-form-select>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Tax" label-for="tax">
          <blueprint-search-input
            id="tax"
            v-model="pricetax.taxId"
            :state="state('taxId')"
            placeholder="Type to search"
            api-route-path="inventory/taxes"
          />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="editTaxPrice()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal ref="meta" no-enforce-focus hide-header>
      <form @submit.prevent="editMeta">
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Lastname">
          <b-form-input id="firstname" v-model="meta.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Firstname">
          <b-form-input id="lastname" v-model="meta.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Document No.">
          <b-form-input id="documentno" v-model="meta.documentno" :state="state('documentno')" type="text" placeholder="Document No." />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Birthday">
          <!-- <datepicker v-model="meta.birthday" /> --->
          <b-form-input id="birthday" v-model="meta.birthday" :state="state('birthday')" type="text" placeholder="YYYY-MM-DD" />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Invoices e-mail">
          <b-form-input id="invoicesEmail" v-model="meta.invoicesEmail" :state="state('invoicesEmail')" type="text" placeholder="Invoices email address" />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Company" label-for="company">
          <blueprint-search-input
            id="company"
            v-model="meta.companyId"
            :state="state('companyId')"
            placeholder="Type to search"
            api-route-path="companies"
          />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Street">
          <b-form-input id="street" v-model="meta.street" :state="state('street')" type="text" placeholder="Street" />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="City">
          <b-form-input id="city" v-model="meta.city" :state="state('city')" type="text" placeholder="City" />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Zip code">
          <b-form-input id="zip" v-model="meta.zip" :state="state('zip')" type="text" placeholder="Zip" />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="State">
          <b-form-input id="state" v-model="meta.state" :state="state('state')" type="text" placeholder="State" />
        </b-form-group>
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Country">
          <b-form-input id="country" v-model="meta.country" :formatter="toUpperCase" :state="state('country')" type="text" placeholder="ISO 3166 Country Code" />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="editMeta()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-card :title="`Booking No. ${item.number }`" style="text-align: center">
      <b-button-toolbar>
        <b-button-group class="mx-auto">
          <b-dropdown :disabled="item.status==='CANCELED' || item.status=='CLOSED'" :variant="getStatusVariant(item.status)" :text="getStatusText(item.status)">
            <b-dropdown-item :disabled="item.status!=='NEW'" @click="setStatus('CONFIRMED')">
              Confirm
            </b-dropdown-item>
            <b-dropdown-item :disabled="item.status!=='NEW' && item.status!=='CONFIRMED'" @click="setStatus('ACTIVATED')">
              Activate
            </b-dropdown-item>
            <b-dropdown-item :disabled="item.status!=='ACTIVATED'" @click="setStatus('CLOSED')">
              Close
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item :disabled="item.status==='CANCELED' || item.status=='CLOSED'" @click="setStatus('CANCELED')">
              Cancel
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </b-button-toolbar>
    </b-card>
    <br>
    <b-card>
      <a href="#" class="abs text-primary" title="Edit" @click.prevent="openEditTax()"><fa-icon icon="edit" /></a>
      <b-row v-if="item.unit">
        <b-col><fa-icon icon="table" /> {{ item.unit.name }} / {{ item.unit.property.name }}</b-col>
        <b-col v-if="item.tax && item.tax.percentage">
          <fa-icon icon="money-bill-wave-alt" /> {{ parseFloat(item.price).toFixed(2) }}/{{ item.priceMethod }}
          <small>+ {{ item.tax.name }} ({{ item.tax.percentage }}%)</small>
        </b-col>
      </b-row>
    </b-card>
    <br>
    <b-card>
      <a href="#" class="abs text-primary" title="Edit" @click.prevent="openEditRange()"><fa-icon icon="edit" /></a>
      <b-row>
        <b-col v-if="item.range">
          <fa-icon icon="calendar" /> {{ item.range.start | moment('LL') }}
        </b-col>
        <b-col v-if="item.range">
          <fa-icon icon="calendar" /> {{ item.range.end | moment('LL') }}
        </b-col>
      </b-row>
    </b-card>
    <br>

    <b-card>
      <a href="#" class="abs text-primary" title="Edit" @click.prevent="openEditMeta()"><fa-icon icon="edit" /></a>
      <fa-icon icon="file-alt" /> <span v-if="item.company"> {{ item.company.name }} /</span> {{ item.firstname }} {{ item.lastname }}
      <br>
      {{ item.street }}<span v-if="item.street">, </span>{{ item.city }}  {{ item.zip }}<span v-if="item.city || item.zip">, </span> {{ item.state }}
      <br v-if="item.state">{{ item.country }}
    </b-card>
    <br>
    <b-card>
      <a href="#" class="abs text-primary" title="Edit" @click.prevent="openEditOwner()"><fa-icon icon="edit" /></a>
      <div v-if="item.owner">
        <fa-icon icon="user" /> {{ item.owner.firstname }} {{ item.owner.lastname }}  {{ item.owner.email }}  {{ item.owner.phone }}
      </div>
      <div v-if="!item.owner">
        No Cloud user marked as owner.
      </div>
    </b-card>

    <br>
    <b-card>
      <file-manager v-if="filesPath" :api-route-path="filesPath" accept=".odt,.gif,.png,.jpg,.doc,.docx,.pdf,.xlsx,.xlsm,.xltx,.xltm,.txt,.csv,.ppt,.pptx,.dotm,.docm,.dot,.xml,.html,.htm,.rtf,.ods,.ots,.uos" />
    </b-card>
  </div>
</template>
<style lang="scss" scoped>
  .abs { position: absolute; top: 5px; right: 5px;}
</style>

<script>
import * as _ from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar, faUser, faEdit, faFileAlt, faTable, faMoneyBillWaveAlt,
} from '@fortawesome/free-solid-svg-icons';
import DateRange from '../../../components/date-range.vue';
import FileManager from '../../../components/file-manager.vue';
import * as moment from 'moment';

const FORMAT = 'dd MMM yyyy';

library.add(faCalendar, faUser, faEdit, faFileAlt, faTable, faMoneyBillWaveAlt);
export default {

  components: {
    DateRange,
    FileManager,
  },
  props: ['bookingId'],
  data () {
    return {
      format: FORMAT,
      item: {},
      range: {},
      errors: {},
      meta: {},
      ownerId: null,
      filesPath: null,
      pricetax: {},
    };
  },
  async mounted () {
    if (!this.bookingId) {
      throw new Error('Booking id missing for booking acl component.');
    }
    if (!this.$store.getters.me) {
      await this.$store.dispatch('FETCH_ME');
    }
    if (this.$store.getters.me && this.$store.getters.me.role !== 'USER') {
      this.getBooking();
    }
    this.filesPath = `bookings/${this.bookingId}/files`;
  },
  methods: {
    toUpperCase (value) {
      return value.toUpperCase();
    },
    customUserLabel (item) {
      const fields = [];
      if (item.firstname) {
        fields.push(item.firstname);
      }
      if (item.lastname) {
        fields.push(item.lastname);
      }
      if (item.email) {
        fields.push(item.email);
      }
      if (item.phone) {
        fields.push(item.phone);
      }
      return fields.join(' ');
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    getStatusVariant (status) {
      switch (status) {
        case 'NEW':
          return 'info';
        case 'CONFIRMED':
          return 'warning';
        case 'CANCELED':
          return 'danger';
        case 'ACTIVATED':
          return 'success';
        default:
          return 'dark';
      }
    },
    getStatusText (status) {
      switch (status) {
        case 'NEW':
          return 'New';
        case 'CANCELED':
          return 'Canceled';
        case 'CONFIRMED':
          return 'Confirmed';
        case 'ACTIVATED':
          return 'Active';
        case 'CLOSED':
          return 'Closed';
        default:
          return '-';
      }
    },
    getBooking () {
      const id = this.bookingId;
      return this.$http.get(`bookings/${id}`)
        .then((res) => {
          this.item = res.data;
        })
        .catch(this.err);
    },
    setStatus (status) {
      const id = this.bookingId;
      return this.$http.patch(`bookings/${id}`, { status })
        .then(() => this.getBooking())
        .catch(this.err);
    },
    openEditRange () {
      const range = _.cloneDeep(this.item.range);
      range.start = new Date(range.start);
      range.end = new Date(range.end);
      this.range = range;
      this.$refs.range.show();
    },
    openEditMeta () {
      this.meta = _.cloneDeep(_.pick(this.item, ['firstname', 'lastname', 'companyId', 'invoicesEmail', 'street', 'city', 'zip', 'state', 'country', 'documentno', 'birthday']));
      if (this.meta.birthday) {
        this.meta.birthday = moment(this.meta.birthday).format('YYYY-MM-DD');
      }
      this.$refs.meta.show();
    },
    openEditOwner () {
      this.ownerId = this.item.ownerId;
      this.$refs.owner.show();
    },
    openEditTax () {
      this.pricetax = {
        taxId: this.item.taxId,
        price: this.item.price,
        priceMethod: this.item.priceMethod
      };
      this.$refs.tax.show();
    },
    editRange () {
      const { range } = this;
      this.patch({ range }).then(() => this.$refs.range.hide());
    },
    editMeta () {
      const { meta } = this;
      this.patch(meta).then(() => this.$refs.meta.hide());
    },
    editOwner () {
      const { ownerId } = this;
      this.patch({ ownerId }).then(() => this.$refs.owner.hide());
    },
    editTaxPrice () {
      this.patch(this.pricetax).then(() => this.$refs.tax.hide());
    },
    patch (data) {
      const id = this.bookingId;
      return this.$http.patch(`bookings/${id}`, data)
        .then(() => this.getBooking())
        .catch(this.err);
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
  },
};
</script>
